import Vue from 'vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import ElSelectTree from 'el-select-tree'
import App from './App.vue'

Vue.use(ElementUI)
Vue.use(ElSelectTree)

Vue.prototype.getTableHeight = function () {
  return document.body.clientHeight - 86 - 51.2 - 20 - 20 - 32 - 10
}

Vue.prototype.getTableHeightNoPage = function () {
  return document.body.clientHeight - 86 - 51.2 - 20 - 20
}

Vue.prototype.checkPermission = function (arr) {
  const permissionList = store.state.user.permissionList
  if (!permissionList) {
    return false
  }
  for (let str of arr) {
    for (let permission of permissionList) {
      if (str === permission.permissionCode) {
        return true
      }
    }
  }
  return false
}

Vue.prototype.checkRole = function (arr) {
  const roleList = store.state.user.roleList
  if (!roleList) {
    return false
  }
  for (let str of arr) {
    for (let role of roleList) {
      if (str === role.code) {
        return true
      }
    }
  }
  return false
}

Vue.prototype.isNumber = function (num) {
  return /^-?[0-9]+\.?[0-9]*$/.test(num)
}

Vue.prototype.isInteger = function (num) {
  return /^-?[0-9]+$/.test(num)
}

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app')
