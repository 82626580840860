<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
  /* overflow: hidden; */
}
.el-form-item__label {
  font-weight: bold;
}
.el-select {
  width: 100%;
}
.el-input-number {
  width: 100%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-dialog {
  margin-top: 10px !important;
}
.el-dialog__header {
  padding-bottom: 20px;
}
.el-dialog__footer {
  padding-top: 0;
}
.el-dialog__body {
  padding: 0;
  padding-right: 20px;
}
.el-pagination {
  margin-top: 10px;
  float: right;
}
.el-select-tree {
  width: 100%;
}
.el-input:focus {
  outline: none;
}
.el-dropdown-selfdefine:focus {
  outline: none;
}
.el-tooltip:focus {
  outline: none;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
/* #nprogress .bar {
  background: green !important;
} */
</style>
