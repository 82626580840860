import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'
import { getToken } from '@/common/token'
import system from '@/router/system'
import main from '@/router/main'
import business from '@/router/business'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        ...system,
        ...main,
        ...business
      ]
    },
    {
      path: '/login',
      component: () => import('@/views/Login')
    }
  ]
})

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.path !== '/login' && !getToken()) {
    next('/login')
    return
  }
  if (to.name) {
    const tagList = store.state.tagList.slice(0)
    if (tagList.length === 0) {
      tagList.push({ path: '/', name: '首页', closable: false, effect: 'dark' })
      store.commit('tagList', tagList)
    }
    let isExist = false
    for (let i = 0; i < tagList.length; i++) {
      if (tagList[i].path === to.path) {
        isExist = true
        tagList[i].effect = 'dark'
      } else {
        tagList[i].effect = 'plain'
      }
    }
    if (!isExist) {
      tagList.push({ path: to.path, name: to.name, closable: true, effect: 'dark' })
      store.commit('tagList', tagList)
    }
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
