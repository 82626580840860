import Vue from 'vue'
import Vuex from 'vuex'
import { removeToken } from '@/common/token'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    tagList: [],
    isLoading: false,
  },
  getters: {},
  mutations: {
    user(state, payload) {
      state.user = payload
    },
    tagList(state, payload) {
      state.tagList = payload
    },
    isLoading(state, payload) {
      state.isLoading = payload
    },
  },
  actions: {
    init({ commit, state }, payload) {
      removeToken()
      commit('tagList', [])
    },
  },
  modules: {},
})
