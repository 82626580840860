export default [
  {
    path: '/userScore',
    name: '用户积分',
    component: () => import('@/views/main/UserScore')
  },
  {
    path: '/scoreRule',
    name: '积分规则',
    component: () => import('@/views/main/ScoreRule')
  },
  {
    path: '/level',
    name: '等级管理',
    component: () => import('@/views/main/Level')
  },
  {
    path: '/customerService',
    name: '客服管理',
    component: () => import('@/views/main/CustomerService')
  },
  {
    path: '/campPerfectAudit',
    name: '营地完善审核',
    component: () => import('@/views/main/CampPerfectAudit')
  },
  {
    path: '/campClaim',
    name: '营地认领',
    component: () => import('@/views/main/CampClaim')
  },
  {
    path: '/camp',
    name: '营地信息',
    component: () => import('@/views/main/Camp')
  },
  {
    path: '/welcomePage',
    name: '欢迎页',
    component: () => import('@/views/main/WelcomePage')
  },
  {
    path: '/officialActivities',
    name: '官方活动',
    component: () => import('@/views/main/OfficialActivities')
  },
  {
    path: '/activity',
    name: '活动管理',
    component: () => import('@/views/main/Activity')
  },
  {
    path: '/activity2',
    name: '攒个局',
    component: () => import('@/views/main/Activity2')
  },
  {
    path: '/sensitivewords',
    name: '敏感词管理',
    component: () => import('@/views/main/SensitiveWords')
  }
]
