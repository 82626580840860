export default [
  {
    path: '/examine',
    name: '营地发布审核',
    component: () => import('@/views/business/Examine')
  },
  {
    path: '/idcard',
    name: '营地认领审核',
    component: () => import('@/views/business/Idcard')
  },
  {
    path: '/message',
    name: '营地信息管理',
    component: () => import('@/views/business/Message')
  },
  {
    path: '/moments',
    name: '营地动态管理',
    component: () => import('@/views/business/Moments')
  },
  {
    path: '/comment',
    name: '评论管理',
    component: () => import('@/views/business/Comment')
  },
  {
    path: '/moments/:campId',
    name: '营地动态管理',
    component: () => import('@/views/business/Moments')
  },
  {
    path: '/comment/:momentsId',
    name: '评论管理',
    component: () => import('@/views/business/Comment')
  }
]
